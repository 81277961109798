@import "../../index.scss";

.nav-container {
    display: flex;
    justify-content: center;
    grid-row-start: 1;
    grid-row-end: 2;
    // position: absolute;
    height: 6rem;
    width: 100%;
    background-color: $dark-purple;
    flex-shrink: 0;
}

.expanded-nav-control {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 0 6rem;
    max-width: 60rem;
    @media only screen and (max-width: 900px) {
        margin: 0 4rem;
        max-width: 50rem;
    }
    @media only screen and (max-width: 550px) {
        margin: 0 2rem;
    }
}

.nav-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.foot-container {
    width: 100%;
    flex-direction: row;
    padding: 4rem 6rem;
    background-color: $black;
    @media only screen and (max-width: 900px) {
        padding: 4rem 4rem;
    }
    @media only screen and (max-width: 550px) {
        padding: 2rem 2rem;
    }
}

.footer-inside-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 60rem;
    margin-bottom: 2rem;
    
    @media only screen and (max-width: 900px) {
        flex-direction: column;
        align-items: flex-start;
        max-width: 50rem;
    }
}

footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    margin-top: auto;
    max-width: 60rem;
    
    @media only screen and (max-width: 900px) {
        max-width: 50rem;
    }
}

.routes-holder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer-icon {
    margin-bottom: 1rem;
    height: 2.4rem;
    width: 2.4rem;
    object-fit: cover;
}