@import '../../index';

.title-text-spacer {
    margin-left: 0rem;

    @media only screen and (max-width: 700px) {
        margin-left: 0.75rem;
    }
}

.button-spacer {
    margin-bottom: 0rem;

    @media only screen and (max-width: 700px) {
        margin-bottom: 1rem;
    }
}

.placeholder {
    width: 300px;
    height: 300px;
    background-color: lightgray;
}

.call-to-action-button {
    padding: 1rem 2rem;
    border-radius: 10rem;
    cursor: pointer;
    background: linear-gradient(to left, $light-purple 0%, $pink 50%, $light-purple 100%);
    background-size: 200%;
    transition: 0.6s;
}

.call-to-action-button:hover {
    background-position: right;
}

.hero-image {
    width: 50%;
    object-fit: contain;

    @media only screen and (max-width: 700px) {
        width: 80%;
    }

    @media only screen and (max-width: 550px) {
        width: 100%;
    }
}

.tint-image {
    filter: brightness(0) invert(1);
}

.text-container {
    position: relative; /* Enable stacking for overlay */
    overflow: hidden; /* Clip overflowing content */
    cursor: default;
}

.overlay-text {
    position: absolute; /* Position text on top */
    top: 0;
    left: 0;
    z-index: 0;
}