$buttonBorderRadius: 25px;

$dark-purple: #3c3861;
$purple: #626EEB;
$light-purple: #9E8CFF;

$blue: #0195ba;
$light-blue: #1fc7ff;

$pink: #FF71BC;
$light-pink: #ff96ce;

$peach: #f5dcdc;
$orange-peach: #FF7F56;
$red: #d6204e;
$white: #FFFFFF;
$black: #000000;

::-moz-selection {
  background: $light-purple;
}
::-webkit-selection {
  background: $light-purple;
}
::selection {
  background: $light-purple;
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
}

#root {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

body {
  font-family: "Jost", sans-serif;
}

.purple-text {
  color: $purple;
}

.white-text {
  color: $white;
}

.pink-text {
  color: $pink;
}

.bold {
  font-weight: 700;
}

a {
  text-decoration: none;
  color: $black;
}

a:hover {
  cursor: pointer;
}

h1 {
  font-size: 3rem;
  font-weight: 700;
  @media only screen and (max-width: 900px) {
    font-size: 2.4rem;
  }
  @media only screen and (max-width: 550px) {
    font-size: 2rem;
  }
}

h2 {
  font-size: 2.6rem;
  font-weight: 600;
  @media only screen and (max-width: 700px) {
    font-size: 1.8rem;
  }
  @media only screen and (max-width: 550px) {
    font-size: 1.6rem;
  }
}
h3 {
  font-size: 2rem;
  font-weight: 500;
  @media only screen and (max-width: 700px) {
    font-size: 1.6rem;
  }
  @media only screen and (max-width: 550px) {
    font-size: 1.4rem;
  }
}
h4 {
  font-size: 1.2rem;
  font-weight: 500;
  @media only screen and (max-width: 700px) {
    font-size: 1rem;
  }
}
h5 {
  font-size: 1rem;
  @media only screen and (max-width: 700px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 550px) {
    font-size: 0.7rem;
  }
}

p {
  font-size: 1rem;
  @media only screen and (max-width: 700px) {
    font-size: 0.875rem;
  }
}

li {
  font-size: 1rem;
  margin-left: 2rem;
  @media only screen and (max-width: 700px) {
    font-size: 0.875rem;
  }
}

button {
  border: 0;
  background-color: transparent;
  :hover {
    cursor: pointer;
  }
  margin: 0;
  font-family: "Jost", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button h4 {
  color: $white;
}

.blue-gradient-button {
  padding: 1rem 2rem;
  border-radius: 100px;
  cursor: pointer;
  background: linear-gradient(to left, $blue 0%, $light-blue 50%, $blue 100%);
  background-size: 200%;
  transition: 0.6s;
}

.blue-gradient-button:hover {
  background-position: right;
}

.red-gradient-button {
  padding: 1rem 2rem;
  border-radius: 100px;
  cursor: pointer;
  background: linear-gradient(to left, $red 0%, $pink 50%, $red 100%);
  background-size: 200%;
  transition: 0.6s;
}

.red-gradient-button:hover {
  background-position: right;
}

.purple-gradient-button {
  padding: 1rem 2rem;
  border-radius: 100px;
  cursor: pointer;
  background: linear-gradient(to left, $purple 0%, $light-purple 50%, $purple 100%);
  background-size: 200%;
  transition: 0.6s;
}

.purple-gradient-button:hover {
  background-position: right;
}

.heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 60rem;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  } 
}

.dark-gradient-container {
  background-color: $black;
  width: 100%;
  background-image: linear-gradient(to top, $black 0%, $black 65%, $dark-purple 100%);
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-width: 700px) {
    align-items: center;
  }
}
  
.title-text-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-width: 700px) {
    flex-direction: row;
    align-items: center;
  }

  @media only screen and (max-width: 450px) {
    flex-direction: column;
  }
}

.heading-copy {
  text-align: left;

  @media only screen and (max-width: 700px) {
    text-align: center;
  }
}

.team-copy {
  max-width: 26rem;

  @media only screen and (max-width: 1150px) {
    max-width: 20rem;
  }

  @media only screen and (max-width: 1000px) {
    max-width: 18rem;
  }

  @media only screen and (max-width: 830px) {
    max-width: 16rem;
  }

  @media only screen and (max-width: 700px) {
    max-width: 100%;
  }
}

.center-text {
  text-align: center;
}

.link {
  text-decoration: none;
  color: $light-purple;
  transition: all 0.5s ease-in-out;
  :hover {
    color: $pink;
    transition: all 0.5s ease-in-out;
  }
}

.full-left-container {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: flex-start;
}

.admin-container {
  padding: 6rem;
}

.big-img {
  margin-top: 4rem;
  margin-bottom: 4rem;
  height: 500px;
  width: min-content;
}

h1::selection {
  background: $light-purple;
}

.max-width-container {
  width: 100%;
  max-width: 60rem;

  @media only screen and (max-width: 900px) {
    max-width: 50rem;
  }
}

/* alignment ------------------------------------------------ */
.flex-center {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.little-line {
  width: 6rem;
  height: 0.25rem;
  border-radius: 5rem;
  background-color: #000000;
}

.insta-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  
  width: 100%;
  max-width: 60rem;
  @media only screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    max-width: 50rem;
  }
}
.little-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  
  width: 100%;
  max-width: 60rem;
  @media only screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    max-width: 50rem;
  }
  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    max-width: 50rem;
  }
}

/* margins ------------------------------------------------ */
.margin-bottom-642 {
  margin-bottom: 6rem;
  
  @media only screen and (max-width: 900px) {
    margin-bottom: 4rem;
  }
  
  @media only screen and (max-width: 700px) {
    margin-bottom: 2rem;
  }
}

.margin-top-642 {
  margin-top: 6rem;
  
  @media only screen and (max-width: 900px) {
    margin-top: 4rem;
  }
  
  @media only screen and (max-width: 700px) {
    margin-top: 2rem;
  }
}

.margin-bottom-42 {
    margin-bottom: 4rem;
  
  @media only screen and (max-width: 900px) {
    margin-bottom: 2rem;
  }
}

.margin-top-42 {
  margin-top: 4rem;
  
  @media only screen and (max-width: 900px) {
    margin-top: 2rem;
  }
}

.margin-bottom-21 {
  margin-bottom: 2rem;
  
  @media only screen and (max-width: 700px) {
    margin-bottom: 1rem;
  }
}

.compact-margin-bottom-21 {
  margin-bottom: 2rem;
  
  @media only screen and (max-width: 1000px) {
    margin-bottom: 1rem;
  }
}

.margin-left-2 {
  margin-left: 2rem;
}
.margin-left-1 {
  margin-left: 1rem;
}

.margin-bottom-3 {
  margin-bottom: 3rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

/* padding ------------------------------------------------ */
.comfy-padding-642 {
  padding: 6rem;

  @media only screen and (max-width: 900px) {
    padding: 4rem;
  }

  @media only screen and (max-width: 550px) {
    padding: 2rem;
  }
}

.padding-642 {
  padding: 6rem;

  @media only screen and (max-width: 900px) {
    padding: 4rem;
  }

  @media only screen and (max-width: 700px) {
    padding: 2rem;
  }
}

.padding-bottom-642 {
  padding-bottom: 6rem;

  @media only screen and (max-width: 900px) {
    padding-bottom: 4rem;
  }

  @media only screen and (max-width: 700px) {
    padding-bottom: 2rem;
  }
}

.padding-42 {
    padding: 4rem;

  @media only screen and (max-width: 700px) {
    padding: 2rem;
  }
}

.padding-bottom-42 {
  padding-bottom: 4rem;

@media only screen and (max-width: 700px) {
  padding-bottom: 2rem;
}
}

.padding-211 {
  padding: 1rem 2rem 2rem 2rem;

  @media only screen and (max-width: 700px) {
    padding: 1rem;
  }
}
.padding-121 {
  padding: 2rem 2rem 1rem 2rem;

  @media only screen and (max-width: 700px) {
    padding: 1rem;
  }
}

.padding-21 {
  padding: 2rem;

  @media only screen and (max-width: 700px) {
    padding: 1rem;
  }
}
.padding-21-horizontal {
  padding: 0 2rem;

  @media only screen and (max-width: 700px) {
    padding: 0 1rem;
  }
}

.padding-bottom-21 {
  padding-bottom: 2rem;

  @media only screen and (max-width: 700px) {
    padding-bottom: 1rem;
  }
}

.padding-6 {
  padding: 6rem;
}

.padding-4 {
  padding: 4rem;
}

.padding-2 {
  padding: 2rem;
}

.margin-left-3 {
  margin-left: 3rem;
}